import actions from './actions';

const initState = {
  suppliers: {
    list: [],
    totalItems: 0,
    loading: false,
  },
};

export default function suppliersReducer(state = initState, action) {
  switch (action.type) {
    //#region SUPPLIERS
    case actions.GET_SUPPLIERS_LIST:
      return { ...state, suppliers: { ...state.suppliers, loading: true } };

    case actions.GET_ALL_SUPPLIERS_LIST:
      return { ...state, suppliers: { ...state.suppliers, loading: true } };
    case actions.GETALL_SUPPLIERS_LIST_SUCCESS: {
      const { data, totalItems } = action?.payload;
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          listAll: data || [],
          loading: false,
          totalItems: totalItems,
        },
      };
    }
    case actions.GET_SUPPLIERS_LIST_SUCCESS: {
      const { data, totalItems } = action?.payload;
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          list: data || [],
          loading: false,
          totalItems: totalItems,
        },
      };
    }

    case actions.UPDATE_SUPPLIER:
      return {
        ...state,
        model: action?.payload?.model,
        suppliers: {
          ...state.suppliers,
          loading: false,
        },
      };

    case actions.GET_SUPPLIERS_LIST_ERROR:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          loading: false,
          totalItems: 0,
          // brandName: null,
          list: [],
        },
      };
    //#endregion
    default:
      return state;
  }
}
