import 'antd/dist/antd.min.css';
import { createGlobalStyle } from 'styled-components';
import { font, palette } from 'styled-theme';
import colors from './variables';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Lato';
    src: url('/src/assets/fonts/Lato-Light.woff2') format('woff2'),
        url('/src/assets/fonts/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Lato';
    src: url('/src/assets/fonts/Lato-Black.woff2') format('woff2'),
        url('/src/assets/fonts/Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Lato';
    src: url('/src/assets/fonts/Lato-Bold.woff2') format('woff2'),
        url('/src/assets/fonts/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Lato';
    src: url('/src/assets/fonts/Lato-Regular.woff2') format('woff2'),
        url('/src/assets/fonts/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Saira';
    src: url('/src/assets/fonts/Saira-Bold.woff2') format('woff2'),
        url('/src/assets/fonts/Saira-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
      font-family: 'Saira';
      src: url('/src/assets/fonts/Saira-Medium.woff2') format('woff2'),
          url('/src/assets/fonts/Saira-Medium.woff') format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Saira';
      src: url('/src/assets/fonts/Saira-Regular.woff2') format('woff2'),
          url('/src/assets/fonts/Saira-Regular.woff') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Saira';
      src: url('/src/assets/fonts/Saira-SemiBold.woff2') format('woff2'),
          url('/src/assets/fonts/Saira-SemiBold.woff') format('woff');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: ${palette('color', 14)};
  }

  .ant-table-wrapper {
    font-family: "Saira", sans-serif;
    &.bordered-table {
      .ant-table-thead > tr {
      border: 1px solid ${palette('color', 15)};
      th:not(:last-child) {
        border-right: 1px solid ${palette('color', 15)};
        }
        th {
          color: ${palette('color', 15)};
          border-bottom: 1px solid ${palette('color', 15)};
          background: ${palette('color', 14)};
          white-space: break-spaces;
          text-align: center;
          padding: 4px;
        }
      }
    }
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-select-selector {
    max-height: 200px;
    overflow-x: auto;
  }
  // .ant-select-selection-overflow-item{
  //   width: 100%;
  // }
  // .ant-select-selection-overflow-item .ant-select-selection-item-remove{
  //   margin-left:auto;
  // }
  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: ${palette('color', 15)};
        font-family: 'Lato';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;

      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: ${palette('color', 14)};
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
    &-menu {
      border-radius: 4px !important;
      &-item {
          &:hover {
          color: ${palette('color', 19)};
        }
        &-has-total {
          justify-content: flex-start;
          display: flex;
          align-items: center;
          .total-items {
            margin-left: 12px;
            border-radius: 50%;
            width: 24px;
            height: 24px;

            background-color: ${palette('primary', 0)};
            color: ${palette('color', 14)};
            font-size: 11px;
            font-weight: 500;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #1e1e1e;
          text-transform: capitalize;
        }
      }
    }
    .ant-popover-buttons {
      .ant-btn {
        border: 0;
        &:not(:last-child) {
          margin-right: 4px;
        }
        span {
          text-decoration: underline;
        }
        &-primary {
          border-radius: 4px;
          padding: 2px 12px;
          height: auto;
          span {
            text-decoration: none;
          }
        }
      }
    }
    .ant-popover-inner {
      border-radius: 4px;
    }
    .ant-popover-content {
      .ant-popover-message .categories-confirm {
        .main-title {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/

  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-close {
          top: -5px;
        }
        .ant-modal-header, .ant-modal-footer {
          padding: 10px 16px;
        }
        .ant-modal-body {
          padding: 16px;
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
          .password-reset-here {
            background: rgba(0, 177, 106, 0.1);
            color: #00b16a;
            margin-bottom: 16px;

            width: fit-content;
            padding: 2px 16px;
            border-radius: 4px;
            .anticon {
              margin-right: 4px;
            }
            i {
              font-weight: 600;
            }
          }
          .reset-password {
            height: 100%;
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: flex-end;

            .reset-btn {
              height: 35px;
              background: #4482ff;
              color: ${palette('color', 14)};
              border-radius: 4px;
              border: 0;
              &:focus,
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
      &.no-modal-footer .ant-modal-content {
        .ant-modal-footer {
          display: none !important;
        }
      }
      &.modal-expenses .ant-modal-footer {
        padding: 16px;
        border-top: 1px solid ${palette('border', 5)};
      }
      &.modal-confirm-delete {
        .ant-modal-content {
          position: relative;
          .icon-delete-outer {
            position: absolute;
            top: -60px;
            left: 115px;
            &.category-type {
              left: 145px;
            }
          }
          .ant-modal-footer {
            border-top: 0;
          }
        }
      }
    }
  }

/*-----------------------------------------------*/
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: "Saira" , sans-serif;

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette('primary', 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${
    '' /* margin-left: -8px;
  margin-right: -8px; */
  };
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/*-----------------------------------------------*/
// style for select
/*-----------------------------------------------*/
#wrapper_sales{
  .ant-select{
    .ant-select-selector{
      width: 120px !important ;
    }
  }
}
.ant-select {
  .ant-select-selector {
    border-radius: 4px !important;
    .ant-select-selection-search input {
      height: 100%;
    }
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    ) {
    .ant-select-selector {
      border-color: ${colors['orangeE9A']} !important;
      box-shadow: 0 0 0 2px ${colors['orangeE9A']}20 !important;
    }
  }

  .ant-select-item.ant-select-item-option-selected {
    background-color: ${colors['orangeE9A']}25;
  }

  &:hover {
    .ant-select-selector {
      border-color: ${colors['orangeE9A']} !important;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: ${colors['orangeE9A']}25 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: ${colors['orangeE9A']} !important;
}

/*-----------------------------------------------*/
// style for input
/*-----------------------------------------------*/

.ant-input {
  padding: 0 16px !important;
  height: 35px;
  border-radius: 4px !important;
  &::placeholder {
    font-size: 14px;
    color: ${palette('color', 17)};
  }

  &:hover, &:focus {
    border-color: ${colors['orangeE9A']} !important;
  }

  &:focus {
    box-shadow: 0 0 0 2px ${colors['orangeE9A']}20 !important;
  }
}

.ant-input-affix-wrapper{
  &:hover, &:focus-within  {
    border-color: ${colors['orangeE9A']} !important;
  }

  &:focus, &.ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px ${colors['orangeE9A']}20 !important;
  }
}

/*-----------------------------------------------*/
// style for button
/*-----------------------------------------------*/

.ant-btn {
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 4px 10px !important;
  height: auto !important;
  box-shadow: none !important;
  border-radius: 5px !important;

  &.primary-yellow.ant-btn-primary{
    border-color: ${colors['orangeE9A']} !important;
    background: ${colors['orangeE9A']} !important;

    &:hover, &:focus {
      opacity: 0.8;
    }
  }

  &.secondary-orange.ant-btn-text {
    color: ${colors['orangeED8']} !important;
    &:hover, &:focus {
      background-color: ${colors['orangeED8']}10;
    }
  }

  &.secondary-yellow.ant-btn-text {
    color: ${colors['orangeE9A']} !important;
    &:hover, &:focus {
      background-color: ${colors['orangeE9A']}10;
    }
  }

  &.ant-btn-icon-only {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors['orangeE9A']};
    border-color: ${colors['orangeE9A']};

    .anticon {
      height: 16px;
      width: 16px;
    }

    &:hover, &:focus {
      color: ${colors['orangeE9A']};
      border-color: ${colors['orangeE9A']};
    }
  }

  &:disabled {
    background: transparent;
    background-color: ${colors['grayF5F']};
    color: ${colors['black1E1']}40;
    cursor: auto;
    pointer-events: none;
  }
}

/*-----------------------------------------------*/
// style for table
/*-----------------------------------------------*/
.ant-table-column-sorter-up,.ant-table-column-sorter-down.active  {
  &.active {
    color: ${colors['orangeED8']} !important;
  }
}

.highlighted-row {
  background-color: #E9A14B;
  padding:0px 0px;
  border-radius:6px;
  text-align: center;
}

.table__create{
  display:flex;
  justify-content: space-between;
  padding: 10px 30px;
  align-items:center;
}
.table__create_warning{
  display:flex;
  justify-content: space-between;
  padding: 0px 30px 20px 6px;
  align-items:center;
}
.title_createTable{
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}
.date___pickkerr{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
}
.datePicker__{
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 4px;
  height:100%;
}
.button__shippedProduct{
  margin-left:auto;
}
.searchWraper{
  width:100%;
}
.modal_door{
  width:100%;
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding: 0px 40px;
}
.createTransfer__group{
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  font-weight:bold;
  .select_transfer{
    min-width:100px !important;
  }
  .ant-picker{
    width:100%;
  }
}
.select_transfer{
  width:100%;
  text-align:center;
  font-weight:normal;
  padding:24px 0 0 0;
  button{
    width:140px;
  }
}
.brandListHeader{
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding: 0 12px;
  margin-right:28px;
}

.ant-modal{
  top:50px !important;
  padding: revert !important;
  max-height: 86vh;
    overflow: auto;
  .ant-modal-body{
    max-height: 80vh;
    overflow: auto;
  }
}

.modelTransfer{
  .ant-table-expanded-row{
    .ant-table{
      max-height: 60vh !important;
      overflow:auto;
    }
  }
}
.ant_eanInput{
  padding: 8px !important;
}
`;

export default GlobalStyles;
