const actions = {
  GET_DOOR_LIST: 'GET_DOOR_LIST',
  GET_DOOR_LIST_SALESFORCE: 'GET_DOOR_LIST_SALESFORCE',
  GET_DOOR_LIST_SUCCESS: 'GET_DOOR_LIST_SUCCESS',
  GET_DOOR_LIST_SALESFORCE_SUCCESS: 'GET_DOOR_LIST_SALESFORCE_SUCCESS',
  GET_DOOR_LIST_ERROR: 'GET_DOOR_LIST_ERROR',

  CREATE_DOOR: 'CREATE_DOOR',
  UPDATE_DOOR_DETAIL: 'UPDATE_DOOR',
  UPDATE_DOOR_DETAIL_SUCCESS: 'UPDATE_DOOR_SUCCESS',
  UPDATE_DOOR_DETAIL_ERROR: 'UPDATE_DOOR_ERROR',
  DELETE_DOOR: 'DELETE_DOOR',

  getDoorList: (model, _cbError) => ({
    type: actions.GET_DOOR_LIST,
    payload: { model },
    _cbError,
  }),

  getDoorListSalesforce: (model, _cbError) => ({
    type: actions.GET_DOOR_LIST_SALESFORCE,
    payload: { model },
    _cbError,
  }),

  createDoor: (body, _cbSuccess, _cbError) => ({
    type: actions.CREATE_DOOR,
    payload: { body },
    _cbSuccess,
    _cbError,
  }),

  updateDoor: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_DOOR_DETAIL,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  deleteDoor: (model, _cbSuccess, _cbError) => ({
    type: actions.DELETE_DOOR,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
};

export default actions;
