import authSaga from '@iso/redux/auth/saga';
import commonSaga from '@iso/redux/common/saga';
import userSaga from '@iso/redux/users/saga';
import suppliersSaga from '@iso/redux/suppliers/saga';
import doorSaga from '@iso/redux/door/saga';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    authSaga(),
    commonSaga(),
    userSaga(),
    suppliersSaga(),
    doorSaga(),
  ]);
}
