import {
  deleteToken,
  deleteUserType,
  getToken,
  saveToken,
} from '@iso/lib/helpers/localStorage';
import * as authServices from '@iso/services/auth';
import * as userServices from '@iso/services/user';
import {
  removeAuthorization,
  setAuthorization,
} from '@iso/utils/axios.configs';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

//#region LOGIN
export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* ({ payload, cbError }) {
    const { model } = payload;
    try {
      var response = yield call(authServices.login, model);
      if (response.data) {
        var data = response.data;
        const userDetail = yield call(
          userServices.getUserDetail,
          data.id,
          data.token
        );
        if (userDetail.data) {
          // const role = ;
          // console.log('role: ', role);
          yield saveToken({
            token: data?.token,
            userType: userDetail?.data.user_groups,
            name: data?.name,
          });
          yield setAuthorization(data?.token);
          yield put({
            type: actions.LOGIN_SUCCESS,
            token: data?.token,
          });
        }
      } else {
        let { message } = response.data;
        yield cbError(message);
      }
    } catch (e) {
      if (e.response?.data) yield cbError(e.response?.data?.message);
      else yield cbError();
    }
  });
}
//#endregion

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* ({ history }) {
    yield deleteToken();
    yield deleteUserType();
    yield removeAuthorization();
    history.push('/');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken();
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    }
  });
}

//#region FORGOT PW
export function* forgotPassword() {
  yield takeEvery(
    actions.FORGOT_PASSWORD,
    function* ({ payload, cbSuccess, cbError }) {
      const { model } = payload;
      try {
        var response = yield call(authServices.forgotPassword, model);
        if (response.data != null) {
          yield cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.message);
        else yield cbError();
      }
    }
  );
}

export function* checkTokenResetPassword() {
  yield takeEvery(
    actions.CHECK_TOKEN_RESET_PASSWORD,
    function* ({ payload, history }) {
      const { model } = payload;
      try {
        var response = yield call(authServices.checkTokenResetPassword, model);
        if (!response.data.success) {
          history.push('/dashboard');
        }
      } catch (e) {
        history.push('/signin');
      }
    }
  );
}

export function* resetPassword() {
  yield takeEvery(
    actions.RESET_PASSWORD,
    function* ({ payload, cbSuccess, cbError }) {
      try {
        var { model } = payload;
        var response = yield call(
          authServices.resetPassword,
          JSON.stringify(model)
        );
        if (response.data.success) {
          yield cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.message);
        else yield cbError();
      }
    }
  );
}
//#endregion

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    // fork(loginSuccess),
    // fork(loginError),
    fork(logout),
    fork(forgotPassword),
    fork(checkTokenResetPassword),
    fork(resetPassword),
  ]);
}
