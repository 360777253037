import actions from './actions';

const initState = {
  users: {
    list: [],
    totalItems: 0,
    loading: false,
  },
  userDetail: {
    detail: {},
    loading: false,
  },
  permissions: {}
};

export default function userReducer(state = initState, action) {
  switch (action.type) {

    //#region CRUD
    case actions.GET_USERS:
      return { ...state, users: { ...state.users, loading: true } };
    case actions.GET_USERS_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, users: { ...state.users, list: res, loading: false, totalItems: totalItems } };
    }
    case actions.GET_USERS_ERROR:
      return { ...state, users: { ...state.users, loading: false, totalItems: 0, list: [] } };

    case actions.GET_USER_DETAIL:
      return { ...state, userDetail: { ...state.userDetail, loading: true } };
    case actions.GET_USER_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, userDetail: { ...state.userDetail, loading: false, detail: res } };
    }
    case actions.GET_USER_DETAIL_ERROR:
      return { ...state, userDetail: { ...state.userDetail, loading: false } };
    //#endregion


    //#region ACCESS PERMISSION
    case actions.USER_ACCESS_PERMISSTION:
      return { ...state, loading: true };
    case actions.USER_ACCESS_PERMISSTION_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, permissions: res, loading: false };
    }
    case actions.USER_ACCESS_PERMISSTION_ERROR:
      return { ...state, loading: false };
    //#endregion

    default:
      return state;

  }
}
