import actions from './actions';

const initState = {
  door: {
    list: [],
    listDoorSF: [],
    totalItems: 0,
    loading: false,
  },
};

export default function doorReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_DOOR_LIST:
      return { ...state, door: { ...state.door, loading: true } };

    case actions.GET_DOOR_LIST_SALESFORCE:
      return {
        ...state,
        door: {
          ...state.door,
          loading: true,
        },
      };

    case actions.GET_DOOR_LIST_SALESFORCE_SUCCESS:
      const { data } = action?.payload;
      return {
        ...state,
        door: {
          ...state.door,
          listDoorSF: data || [],
          loading: false,
        },
      };

    case actions.GET_DOOR_LIST_SUCCESS: {
      const { data, totalItems } = action?.payload;
      return {
        ...state,
        door: {
          ...state.door,
          list: data || [],
          loading: false,
          totalItems: totalItems,
        },
      };
    }
    case actions.GET_DOOR_LIST_ERROR:
      return {
        ...state,
        door: {
          ...state.door,
          loading: false,
          totalItems: 0,
          list: [],
        },
      };
    case actions.UPDATE_DOOR_DETAIL:
      return {
        ...state,
        model: action?.payload?.model,
        door: {
          ...state.door,
          loading: false,
        },
      };

    case actions.CREATE_DOOR:
      // console.log(action?.payload.body, "action?.payload");
      return {
        ...state,
        // model: action?.payload?.model,
        door: {
          ...state.door,
          loading: false,
        },
      };

    case actions.DELETE_DOOR:
      return {
        ...state,
        model: action?.payload?.model,
        door: {
          ...state.door,
          loading: false,
        },
      };

    case actions.UPDATE_DOOR_DETAIL_SUCCESS:
      return {
        ...state,
        door: {
          ...state.door,
          loading: false,
        },
      };
    default:
      return state;
  }
}
