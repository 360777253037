import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";

export const getVersionApi = () => {
  var url = `${ApiRouters.COMMON}/GetVersionApi`;
  return api.get(url);
};

//#region GROUPS
export const getGroupsCommon = () => {
  var url = `${ApiRouters.COMMON}/Groups`;
  return api.get(url);
};
//#endregion

//#region PROFILE TYPES
export const getProfileTypesCommon = () => {
  var url = `${ApiRouters.COMMON}/ProfileTypes`;
  return api.get(url);
};
//#endregion

//#region ADDRESS
export const getProvinces = () => {
  var url = `${ApiRouters.COMMON}/Provinces`;
  return api.get(url);
};
export const getDistricts = (provinceId) => {
  var url = `${ApiRouters.COMMON}/Provinces/${provinceId}/Districts`;
  return api.get(url);
};
export const getWards = (districtId) => {
  var url = `${ApiRouters.COMMON}/Districts/${districtId}/Wards`;
  return api.get(url);
};
//#endregion

//#region SETTINGS
export const getCommonSettingsService = () => {
  var url = `${ApiRouters.COMMON}/getListSettings`;
  return api.get(url);
};

export const updateCommonSettingsService = (body) => {
  var url = `${ApiRouters.COMMON}/updateListSettings`;
  return api.put(url, body);
};
//#endregion

//#region CATEGORY
export const getCategories = (model) => {
  var url = `${ApiRouters.COMMON}/getListCategories`;
  return api.get(url, { params: model });
};

export const getCategoryItemsService = (model) => {
  var url = `${ApiRouters.COMMON}/getListItemFromCategories`;
  return api.get(url, { params: model });
};

export const getCategoryTreeService = () => {
  var url = `${ApiRouters.COMMON}/get-category-tree`;
  return api.get(url);
};

export const getCategoriesFromItemService = (itemId) => {
  var url = `${ApiRouters.COMMON}/getCategoriesFromMarketing/${itemId}`;
  return api.get(url);
};
//#endregion

export const getCommonBrandsService = () => {
  var url = `${ApiRouters.COMMON}/getListBrands`;
  return api.get(url);
};

export const getCommonBrandsForQuoteService = () => {
  var url = `${ApiRouters.COMMON}/getListBrandsQuotes`;
  return api.get(url);
};

export const getAddressByClientService = (model) => {
  var url = `${ApiRouters.COMMON}/getInforAddressClient`;
  return api.get(url, { params: model });
};
