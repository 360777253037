import * as getDoorsService from '@iso/services/door';
import * as commonServices from '@iso/services/common';
import * as moment from 'moment';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

export function* getDoorListSaga() {
  yield takeEvery(actions.GET_DOOR_LIST, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      const response = yield call(getDoorsService.getDoorsService, model);
      if (response.status === 200) {
        const data = response.data.doors;
        const totalItems = response.data.total;

        yield put({
          type: actions.GET_DOOR_LIST_SUCCESS,
          payload: { data, totalItems },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_DOOR_LIST_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_DOOR_LIST_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* getDoorListSaleForceSaga() {
  yield takeEvery(
    actions.GET_DOOR_LIST_SALESFORCE,
    function* ({ payload, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          getDoorsService.getDoorsSalesforceService,
          model
        );
        if (response.status === 200) {
          const data = response.data.door;
          // console.log(data, "data");
          yield put({
            type: actions.GET_DOOR_LIST_SALESFORCE_SUCCESS,
            payload: { data },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_DOOR_LIST_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_DOOR_LIST_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* updateDetailDoorSaga() {
  yield takeEvery(
    actions.UPDATE_DOOR_DETAIL,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      // console.log(model, "model");
      try {
        const response = yield call(getDoorsService.updateDoors, model);
        if (response.status === 200) {
          let message = response.data;
          yield _cbSuccess(message);
          yield put({
            type: actions.UPDATE_DOOR_DETAIL_SUCCESS,
          });
        } else {
          let message = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* deleteDoorSaga() {
  yield takeEvery(
    actions.DELETE_DOOR,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(getDoorsService.deleteDoorsService, model);
        if (response.status === 200) {
          let message = response.data;
          yield _cbSuccess(message);
          // yield put({
          //   type: actions.GET_DOOR_LIST_SUCCESS,
          // });
        } else {
          let message = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* createDoorSaga() {
  yield takeEvery(
    actions.CREATE_DOOR,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { body } = payload;
      try {
        const response = yield call(getDoorsService.createDoorsService, body);
        // console.log(response, "response");
        if (response.status === 200 || response.status === 201) {
          let message = response.data;
          yield _cbSuccess(message);
          // yield put({
          //   type: actions.GET_DOOR_LIST_SUCCESS,
          // });
        } else {
          let message = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

// export function* deleteSupplierSaga() {
//   yield takeEvery(
//     actions.DELETE_SUPPLIER,
//     function* ({ payload, _cbSuccess, _cbError }) {
//       const { model } = payload;
//       try {
//         const response = yield call(brandsService.deleteSupplierService, model);

//         if (response.data.success === true) {
//           yield _cbSuccess();
//         } else {
//           let { message } = response.data;
//           yield _cbError(message);
//         }
//       } catch (e) {
//         if (e.response && e.response.data)
//           yield _cbError(e.response.data.message);
//         else yield _cbError();
//       }
//     }
//   );
// }

// export function* createSupplierSaga() {
//   yield takeEvery(
//     actions.CREATE_SUPPLIER,
//     function* ({ payload, _cbSuccess, _cbError }) {
//       const { body } = payload;
//       try {
//         const response = yield call(brandsService.createSupplierService, body);

//         if (response.data.success) {
//           yield _cbSuccess();
//         } else {
//           let { message } = response.data;
//           yield _cbError(message);
//         }
//       } catch (e) {
//         if (e.response && e.response.data)
//           yield _cbError(e.response.data.message);
//         else yield _cbError();
//       }
//     }
//   );
// }

// export function* updateSupplierSaga() {
//   yield takeEvery(
//     actions.UPDATE_SUPPLIER,
//     function* ({ payload, _cbSuccess, _cbError }) {
//       const { model } = payload;
//       try {
//         const response = yield call(brandsService.updateSupplierService, model);

//         if (response.data.success === true) {
//           yield _cbSuccess();
//         } else {
//           let { message } = response.data;
//           yield _cbError(message);
//         }
//       } catch (e) {
//         if (e.response && e.response.data)
//           yield _cbError(e.response.data.message);
//         else yield _cbError();
//       }
//     }
//   );
// }

// export function* importSuppliersSaga() {
//   yield takeEvery(
//     actions.IMPORT_SUPPLIERS,
//     function* ({ payload, _cbSuccess, _cbError }) {
//       const { body } = payload;
//       try {
//         const response = yield call(brandsService.importSuppliersService, body);

//         if (response.status === 201) {
//           let date = moment(new Date(new Date())).format('YYYY-MM-DD_HH-mm-ss');
//           const url = window.URL.createObjectURL(new Blob([response.data]));
//           const link = document.createElement('a');
//           link.href = url;
//           const fileName = `ImportSuppliersResult_${`${date}`}.xlsx`;
//           link.setAttribute('download', fileName);
//           document.body.appendChild(link);
//           link.click();
//           link.remove();
//           yield _cbSuccess();
//         } else {
//           if (response.status === 202) {
//             _cbError('FILE_MALFORMED');
//           }
//         }
//       } catch (e) {
//         if (e.response && e.response.data)
//           yield _cbError(e.response.data.message);
//         else yield _cbError();
//       }
//     }
//   );
// }

//#endregion

export default function* rootSaga() {
  yield all([
    fork(getDoorListSaga),
    fork(updateDetailDoorSaga),
    fork(deleteDoorSaga),
    fork(createDoorSaga),
    fork(getDoorListSaleForceSaga),
    // fork(importSuppliersSaga),
  ]);
}
