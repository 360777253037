import * as getSuppliersService from '@iso/services/suppliers';
import * as commonServices from '@iso/services/common';
import * as moment from 'moment';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

//#region CRUD SUPPLIERS
export function* getSuppliersListSaga() {
  yield takeEvery(
    actions.GET_SUPPLIERS_LIST,
    function* ({ payload, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          getSuppliersService.getSuppliersService,
          model
        );

        if (response.status === 200) {
          const data = response.data.brand;
          const totalItems = response.data.brand.length;

          yield put({
            type: actions.GET_SUPPLIERS_LIST_SUCCESS,
            payload: { data, totalItems },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_SUPPLIERS_LIST_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_SUPPLIERS_LIST_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}
export function* getAllSuppliersListSaga() {
  yield takeEvery(
    actions.GET_ALL_SUPPLIERS_LIST,
    function* ({ payload, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          getSuppliersService.getAllSuppliersService,
          model
        );

        if (response.status === 200) {
          const data = response.data.brand;
          const totalItems = response.data.brand.length;

          yield put({
            type: actions.GETALL_SUPPLIERS_LIST_SUCCESS,
            payload: { data, totalItems },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_SUPPLIERS_LIST_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_SUPPLIERS_LIST_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* deleteSupplierSaga() {
  yield takeEvery(
    actions.DELETE_SUPPLIER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          getSuppliersService.deleteSupplierService,
          model
        );

        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* createSupplierSaga() {
  yield takeEvery(
    actions.CREATE_SUPPLIER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { body } = payload;
      try {
        const response = yield call(
          getSuppliersService.createSupplierService,
          body
        );

        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* updateSupplierSaga() {
  yield takeEvery(
    actions.UPDATE_SUPPLIER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(getSuppliersService.updateSuppliers, model);
        // if (response.data.success === true) {
        //   yield _cbSuccess();
        // } else {
        //   let { message } = response.data;
        //   yield _cbError(message);
        // }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

// export function* importSuppliersSaga() {
//   yield takeEvery(
//     actions.IMPORT_SUPPLIERS,
//     function* ({ payload, _cbSuccess, _cbError }) {
//       const { body } = payload;
//       try {
//         const response = yield call(
//           getSuppliersService.importSuppliersService,
//           body
//         );

//         if (response.status === 201) {
//           let date = moment(new Date(new Date())).format('YYYY-MM-DD_HH-mm-ss');
//           const url = window.URL.createObjectURL(new Blob([response.data]));
//           const link = document.createElement('a');
//           link.href = url;
//           const fileName = `ImportSuppliersResult_${`${date}`}.xlsx`;
//           link.setAttribute('download', fileName);
//           document.body.appendChild(link);
//           link.click();
//           link.remove();
//           yield _cbSuccess();
//         } else {
//           if (response.status === 202) {
//             _cbError('FILE_MALFORMED');
//           }
//         }
//       } catch (e) {
//         if (e.response && e.response.data)
//           yield _cbError(e.response.data.message);
//         else yield _cbError();
//       }
//     }
//   );
// }

//#endregion

export default function* rootSaga() {
  yield all([
    fork(getSuppliersListSaga),
    fork(getAllSuppliersListSaga),
    fork(deleteSupplierSaga),
    fork(createSupplierSaga),
    fork(updateSupplierSaga),
    // fork(importSuppliersSaga),
  ]);
}
