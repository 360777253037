const colors = {
	whiteFFF: "#ffffff",
	black1E1: "#1E1E1E",
	orangeE9A: "#E9A14B",
	orangeED8: "#ED8169",
	grayF4F: "#F4F4F4",
	grayF8F: "#F8F8F8",
	grayF5F: "#F5F5F5",
	yellowEDB: "#EDB169",
};

export default colors;
