const baseRouter = '/api/';

export const ApiRouters = {
  LOGIN: baseRouter + 'auth/login',
  USER: baseRouter + 'user',
  BRANDS: baseRouter + 'Brand',
  SUPPLIERS: baseRouter + 'suppliers',
  DOORS: baseRouter + 'doors',
  SALES_FORCE: baseRouter + 'salesforce',
  STOCKS: baseRouter + 'warehouse_stock',
  PRODUCT_SHIPPED: baseRouter + 'product_shipped',
  SALES_FILE: baseRouter + 'salesforce_file',
};
