import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Brands from '@iso/redux/brands/reducer';
import Suppliers from '@iso/redux/suppliers/reducer';
import Door from '@iso/redux/door/reducer';
import Common from '@iso/redux/common/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import User from '@iso/redux/users/reducer';
import { combineReducers } from 'redux';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Common,
  User,
  Brands,
  Suppliers,
  Door,
});
