import { ApiRouters } from '@iso/utils/apiRouters';
import { api } from '@iso/utils/axios.configs';

export const getSuppliersService = (model) => {
  var url = ApiRouters.SUPPLIERS;
  return api.get(url, { params: model });
};
export const getAllSuppliersService = (model) => {
  var url = `${ApiRouters.SUPPLIERS}/all`;
  return api.get(url, { params: model });
};
export const deleteSupplierService = (model) => {
  var url = `${ApiRouters.SUPPLIERS}`;
  return api.delete(url, { params: model });
};
export const createSupplierService = (body) => {
  var url = ApiRouters.SUPPLIERS;
  return api.post(url, body);
};
export const updateSuppliers = (model) => {
  var url = `${ApiRouters.SUPPLIERS}?name=${model.name}&active=${model.active}`;
  return api.put(url);
};

export const createNewNameSupplier = (body) => {
  var url = `${ApiRouters.SUPPLIERS}/create-name-supplier`;
  return api.post(url, { params: body });
};

export const updateNewNameSupplier = (body) => {
  var url = `${ApiRouters.SUPPLIERS}/update-name-supplier`;
  return api.put(url, body);
};
export const getNewNameSupplier = () => {
  var url = `${ApiRouters.SUPPLIERS}/get-name-supplier`;
  return api.get(url);
};
