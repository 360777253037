const actions = {
  //#region VERSION
  GET_VERSION_API: "GET_VERSION_API",
  GET_VERSION_API_SUCCESS: "GET_VERSION_API_SUCCESS",
  GET_VERSION_API_ERROR: "GET_VERSION_API_ERROR",

  GET_CATEGORIES_FLAG: "GET_CATEGORIES_FLAG",

  getVersionApi: () => ({
    type: actions.GET_VERSION_API,
  }),
  //#endregion

  setGetCategoriesFlag: (level) => ({
    type: actions.GET_CATEGORIES_FLAG,
    level,
  }),

  //#region GROUPS
  GET_GROUPS_COMMON: "GET_GROUPS_COMMON",
  GET_GROUPS_COMMON_SUCCESS: "GET_GROUPS_COMMON_SUCCESS",
  getGroupsCommon: () => ({
    type: actions.GET_GROUPS_COMMON,
  }),
  //#endregion

  //#region GROUPS
  GET_PROFILE_TYPES_COMMON: "GET_PROFILE_TYPES_COMMON",
  GET_PROFILE_TYPES_COMMON_SUCCESS: "GET_PROFILE_TYPES_COMMON_SUCCESS",
  getProfileTypesCommon: () => ({
    type: actions.GET_PROFILE_TYPES_COMMON,
  }),
  //#endregion

  //#region  ADDRESS
  GET_PROVINCE_COMMON: "GET_PROVINCE_COMMON",
  GET_PROVINCE_COMMON_SUCCESS: "GET_PROVINCE_COMMON_SUCCESS",
  getProvinces: () => ({
    type: actions.GET_PROVINCE_COMMON,
  }),

  GET_DISTRICT_COMMON: "GET_DISTRICT_COMMON",
  GET_DISTRICT_COMMON_SUCCESS: "GET_DISTRICT_COMMON_SUCCESS",
  RESET_DISTRICT_COMMON: "RESET_DISTRICT_COMMON",
  getDistricts: (provinceId) => ({
    type: actions.GET_DISTRICT_COMMON,
    payload: { provinceId },
  }),
  resetDistricts: () => ({
    type: actions.RESET_DISTRICT_COMMON,
  }),

  GET_WARD_COMMON: "GET_WARD_COMMON",
  GET_WARD_COMMON_SUCCESS: "GET_WARD_COMMON_SUCCESS",
  RESET_WARD_COMMON: "RESET_WARD_COMMON",
  getWards: (districtId) => ({
    type: actions.GET_WARD_COMMON,
    payload: { districtId },
  }),
  resetWards: () => ({
    type: actions.RESET_WARD_COMMON,
  }),
  //#endregion

  COMMON_RESPONSE_ERROR: "COMMON_RESPONSE_ERROR",

  //#region SETTINGS
  GET_COMMON_SETTINGS: "GET_COMMON_SETTINGS",
  GET_COMMON_SETTINGS_SUCCESS: "GET_COMMON_SETTINGS_SUCCESS",
  GET_COMMON_SETTINGS_ERROR: "GET_COMMON_SETTINGS_ERROR",

  getCommonSettings: (_cbError) => ({
    type: actions.GET_COMMON_SETTINGS,
    _cbError,
  }),

  UPDATE_COMMON_SETTINGS: "UPDATE_COMMON_SETTINGS",
  updateCommonSettings: (model, _cbSuccess, _cbError) => ({
    payload: { model },
    type: actions.UPDATE_COMMON_SETTINGS,
    _cbSuccess,
    _cbError,
  }),
  //#endregion

  CLEAR_COMMON_STORAGE: "CLEAR_COMMON_STORAGE",
  clearCommonStorage: () => ({
    type: actions.CLEAR_COMMON_STORAGE,
  }),

  //#region CATEGORY
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_ERROR: "GET_CATEGORIES_ERROR",
  getCategories: (model, _cbError) => ({
    type: actions.GET_CATEGORIES,
    payload: { model },
    _cbError,
  }),

  RESET_CATEGORIES: "RESET_CATEGORIES",
  resetCategories: () => ({
    type: actions.RESET_CATEGORIES,
  }),

  RESET_SUB_CATEGORIES: "RESET_SUB_CATEGORIES",
  resetSubCategories: () => ({
    type: actions.RESET_SUB_CATEGORIES,
  }),

  RESET_CATEGORY_ITEMS: "RESET_CATEGORY_ITEMS",
  resetCategoryItems: () => ({
    type: actions.RESET_CATEGORY_ITEMS,
  }),

  GET_CATEGORY_ITEMS: "GET_CATEGORY_ITEMS",
  GET_CATEGORY_ITEMS_SUCCESS: "GET_CATEGORY_ITEMS_SUCCESS",
  GET_CATEGORY_ITEMS_ERROR: "GET_CATEGORY_ITEMS_ERROR",
  getCategoryItems: (model = {}, _cbError) => ({
    type: actions.GET_CATEGORY_ITEMS,
    payload: { model },
    _cbError,
  }),

  GET_CATEGORY_TREE: "GET_CATEGORY_TREE",
  GET_CATEGORY_TREE_SUCCESS: "GET_CATEGORY_TREE_SUCCESS",
  GET_CATEGORY_TREE_ERROR: "GET_CATEGORY_TREE_ERROR",
  getCategoryTree: (_cbError) => ({
    type: actions.GET_CATEGORY_TREE,
    _cbError,
  }),

  GET_CATEGORIES_FROM_ITEM: "GET_CATEGORIES_FROM_ITEM",
  GET_CATEGORIES_FROM_ITEM_SUCCESS: "GET_CATEGORIES_FROM_ITEM_SUCCESS",
  GET_CATEGORIES_FROM_ITEM_ERROR: "GET_CATEGORIES_FROM_ITEM_ERROR",
  getCategoriesFromItem: (itemId, _cbError) => ({
    type: actions.GET_CATEGORIES_FROM_ITEM,
    payload: { itemId },
    _cbError,
  }),
  //#endregion

  GET_COMMON_BRANDS: "GET_COMMON_BRANDS",
  GET_COMMON_BRANDS_SUCCESS: "GET_COMMON_BRANDS_SUCCESS",
  GET_COMMON_BRANDS_ERROR: "GET_COMMON_BRANDS_ERROR",
  getCommonBrands: (_cbError) => ({
    type: actions.GET_COMMON_BRANDS,
    _cbError,
  }),

  GET_COMMON_BRANDS_FOR_QUOTE: "GET_COMMON_BRANDS_FOR_QUOTE",
  GET_COMMON_BRANDS_FOR_QUOTE_SUCCESS: "GET_COMMON_BRANDS_FOR_QUOTE_SUCCESS",
  GET_COMMON_BRANDS_FOR_QUOTE_ERROR: "GET_COMMON_BRANDS_FOR_QUOTE_ERROR",
  _getCommonBrandsForQuote: (_cbError) => ({
    type: actions.GET_COMMON_BRANDS_FOR_QUOTE,
    _cbError,
  }),
};

export default actions;
