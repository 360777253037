const actions = {
  GET_SUPPLIERS_LIST: 'GET_SUPPLIERS_LIST',
  GET_ALL_SUPPLIERS_LIST: 'GET_ALL_SUPPLIERS_LIST',
  GET_SUPPLIERS_LIST_SUCCESS: 'GET_SUPPLIERS_LIST_SUCCESS',
  GETALL_SUPPLIERS_LIST_SUCCESS: 'GETALL_SUPPLIERS_LIST_SUCCESS',
  GET_SUPPLIERS_LIST_ERROR: 'GET_SUPPLIERS_LIST_ERROR',

  CREATE_SUPPLIER: 'CREATE_SUPPLIER',
  UPDATE_SUPPLIER: 'UPDATE_SUPPLIER',
  DELETE_SUPPLIER: 'DELETE_SUPPLIER',
  IMPORT_SUPPLIERS: 'IMPORT_SUPPLIERS',
  DOWNLOAD_SUPPLIER_TEMPLATE: 'DOWNLOAD_SUPPLIER_TEMPLATE',

  getSuppliersList: (model, _cbError) => ({
    type: actions.GET_SUPPLIERS_LIST,
    payload: { model },
    _cbError,
  }),

  getAllSuppliersList: (model, _cbError) => ({
    type: actions.GET_ALL_SUPPLIERS_LIST,
    payload: { model },
    _cbError,
  }),

  createSupplier: (body, _cbSuccess, _cbError) => ({
    type: actions.CREATE_SUPPLIER,
    payload: { body },
    _cbSuccess,
    _cbError,
  }),

  updateSupplier: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_SUPPLIER,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  deleteSupplier: (model, _cbSuccess, _cbError) => ({
    type: actions.DELETE_SUPPLIER,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  importSuppliers: (body, _cbSuccess, _cbError) => ({
    type: actions.IMPORT_SUPPLIERS,
    payload: { body },
    _cbSuccess,
    _cbError,
  }),

  downloadSupplierTemplate: (_cbSuccess, _cbError) => ({
    type: actions.DOWNLOAD_SUPPLIER_TEMPLATE,
    _cbSuccess,
    _cbError,
  }),

  GET_BUDGETS_LIST: 'GET_BUDGETS_LIST',
  GET_BUDGETS_LIST_SUCCESS: 'GET_BUDGETS_LIST_SUCCESS',
  GET_BUDGETS_LIST_ERROR: 'GET_BUDGETS_LIST_ERROR',

  CREATE_BUDGET: 'CREATE_BUDGET',
  UPDATE_BUDGET: 'UPDATE_BUDGET',
  DELETE_BUDGET: 'DELETE_BUDGET',

  GET_BUDGET_FORECAST: 'GET_BUDGET_FORECAST',
  GET_BUDGET_FORECAST_SUCCESS: 'GET_BUDGET_FORECAST_SUCCESS',
  GET_BUDGET_FORECAST_ERROR: 'GET_BUDGET_FORECAST_ERROR',

  UPDATE_BUDGET_FORECAST: 'UPDATE_BUDGET_FORECAST',

  getBudgetsList: (model, _cbError) => ({
    type: actions.GET_BUDGETS_LIST,
    payload: { model },
    _cbError,
  }),

  createBudget: (model, _cbSuccess, _cbError) => ({
    type: actions.CREATE_BUDGET,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  updateBudget: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_BUDGET,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  deleteBudget: (model, _cbSuccess, _cbError) => ({
    type: actions.DELETE_BUDGET,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  getBudgetForecast: (model, _cbError) => ({
    type: actions.GET_BUDGET_FORECAST,
    payload: { model },
    _cbError,
  }),

  updateBudgetForecast: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_BUDGET_FORECAST,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  GET_EXPENSES_LIST: 'GET_EXPENSES_LIST',
  GET_EXPENSES_LIST_SUCCESS: 'GET_EXPENSES_LIST_SUCCESS',
  GET_EXPENSES_LIST_ERROR: 'GET_EXPENSES_LIST_ERROR',

  CREATE_EXPENSE: 'CREATE_EXPENSE',
  UPDATE_EXPENSE: 'UPDATE_EXPENSE',
  CREATE_MULTI_EXPENSE: 'CREATE_MULTI_EXPENSE',
  DELETE_EXPENSE: 'DELETE_EXPENSE',
  IMPORT_EXPENSES: 'IMPORT_EXPENSES',
  DOWNLOAD_EXPENSE_TEMPLATE: 'DOWNLOAD_EXPENSE_TEMPLATE',

  CREATE_INVOICE: 'CREATE_INVOICE',

  getExpensesList: (model, _cbError) => ({
    type: actions.GET_EXPENSES_LIST,
    payload: { model },
    _cbError,
  }),

  createExpense: (model, _cbSuccess, _cbError) => ({
    type: actions.CREATE_EXPENSE,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  updateExpense: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_EXPENSE,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  deleteExpense: (model, _cbSuccess, _cbError) => ({
    type: actions.DELETE_EXPENSE,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  importExpenses: (model, _cbSuccess, _cbError) => ({
    type: actions.IMPORT_EXPENSES,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  downloadExpenseTemplate: (_cbSuccess, _cbError) => ({
    type: actions.DOWNLOAD_EXPENSE_TEMPLATE,
    _cbSuccess,
    _cbError,
  }),

  //#region CATEGORY FOR EXPENSES

  //#region CATEGORY
  GET_EXPENSES_CATEGORIES: 'GET_EXPENSES_CATEGORIES',
  GET_EXPENSES_CATEGORIES_SUCCESS: 'GET_EXPENSES_CATEGORIES_SUCCESS',
  GET_EXPENSES_CATEGORIES_ERROR: 'GET_EXPENSES_CATEGORIES_ERROR',
  _getExpensesCategories: (model, _cbError) => ({
    type: actions.GET_EXPENSES_CATEGORIES,
    payload: { model },
    _cbError,
  }),

  RESET_EXPENSES_CATEGORIES: 'RESET_EXPENSES_CATEGORIES',
  _resetCategories: () => ({
    type: actions.RESET_EXPENSES_CATEGORIES,
  }),

  RESET_EXPENSES_SUB_CATEGORIES: 'RESET_EXPENSES_SUB_CATEGORIES',
  _resetSubCategories: () => ({
    type: actions.RESET_EXPENSES_SUB_CATEGORIES,
  }),
};

export default actions;
