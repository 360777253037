import { ApiRouters } from '@iso/utils/apiRouters';
import { api } from '@iso/utils/axios.configs';

export const getDoorsService = (model) => {
  var url = ApiRouters.DOORS;
  return api.get(url, { params: model });
};
export const getDoorsSalesforceService = (model) => {
  var url = `${ApiRouters.SALES_FORCE}/accountDoor?id=${model.id}`;
  return api.get(url);
};
export const deleteDoorsService = (model) => {
  var url = `${ApiRouters.DOORS}/{id}?id=${model.id}`;
  return api.delete(url);
};

export const createDoorsService = (body) => {
  var url = `${ApiRouters.DOORS}?name=${body.name}&min_stock=${body.min_stock}`;
  return api.post(url);
};

export const updateDoors = (id, body) => {
  var url = `${ApiRouters.DOORS}`;
  return api.put(url, id, body);
};
